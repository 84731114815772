<template>
  <div class="main">
		<div class="main-top">
		  <div class="main-top-title">
		    <!-- 民政养老服务管理系统 -->
		    <img src="../image/logo2.png" alt="" />
		  </div>
		  <div class="main-top-option">
		  </div>
		  <div class="main-top-time">
		    <div class="newTime">
		      <div>
		        {{ newDay }}
		        <span style="margin-left: 10px">{{ newTime }}</span>
		      </div>
		    </div>
		  </div>
		</div>
		<div class="main-left">
		  <div class="item" style="flex: 0 0 auto; height: 200px;">
		    <div class="item-title">探访任务统计</div>
		    <div class="item-main row">
		      <div class="content-item">
						<div class="content-title">探访人员</div>
						<div class="total"><span class="num">{{taskInfo.organizationCount}}</span><span class="unit">人</span></div>
					</div>
		      <div class="content-item">
						<div class="content-title">探访人次</div>
						<div class="total"><span class="num">{{taskInfo.recordCount}}</span><span class="unit">次</span></div>
					</div>
		      <div class="content-item">
						<div class="content-title">探访对象</div>
						<div class="total"><span class="num">{{taskInfo.recordPersonCount}}</span><span class="unit">人</span></div>
					</div>
		    </div>
		    
		  </div>
		  <div class="item" style="flex: 1 1 auto;">
		    <div class="item-title">探访趋势</div>
		    <div class="item-chart">		      
		      <polyline-chart
		        type="line" yName="次数" area :color="color"
				:maxY="tendencyInfo.maxY"
		      	:xData="tendencyInfo.xData"
		      	:series="tendencyInfo.series"
		      ></polyline-chart>
		    </div>
		  </div>
		  <div class="item" style="flex: 1 1 auto;"	>
		    <div class="item-title">老人年龄段统计</div>
		    <div class="item-chart">
		      <polyline-chart
		        type="bar" yName="人" circle :color="color"
						:maxY="ageInfo.maxY"
		      	:xData="ageInfo.xData"
		      	:series="ageInfo.series"
		      ></polyline-chart>
		    </div>
		  </div>
		</div>
		<div class="main-right">
		  <div class="item" style="height: 22%">
		    <div class="item-title">探访类型</div>
		    <div class="item-chart flex">		
					<div class="content-chart">
		        <polyline-chart
		          simple
		          type="rosePie" :pieRadius="['20%','95%']"
		      		:series="typeInfo.data">
		        </polyline-chart>
					</div>
						<div class="content-right" style="align-items:flex-start;overflow: auto;justify-content: normal;">
							<div
							v-for="(item,index) in typeInfo.data"
							:key="index"
							><div class="color-area" :style="{'backgroundColor': typeColors[index],}"></div>
							<div style="white-space: nowrap;">{{item.name}} {{item.value}}次</div>
							</div>
						</div>
		    </div>
		  </div>
		  <div class="item" style="height: 36%">
		    <div class="item-title">各区域老人统计</div>
		    <div class="item-chart">		      
		        <polyline-chart
		          type="bar" yName="人" circle cross :color="colorCross"
							:maxY="areaInfo.maxY"
							:gridLeft="60"
							:gridTop="1"
							:gridBottom="20"
		        	:xData="areaInfo.xData"
		        	:series="areaInfo.series"
		        ></polyline-chart>
		    </div>
		  </div>
		  <div class="item item-record" style="height: 41%; overflow: auto;" >
		    <div class="item-title">探访记录</div>
		    <div class="item-main" style="padding-top: 0px; cursor: pointer;">
			<el-table
				ref="filterTable"
				:data="recordList"
				:max-height="tableHeight"
				style="width: 100%">
				<el-table-column width="100"
					prop="visitTime"
					label="时间">
					<!-- <template slot-scope="scope">
						{{scope.row.visitTime.substr(5, 11)}}
					</template> -->
				</el-table-column>
				<el-table-column
					prop="personIdText"
					label="老人">
				</el-table-column>
				<el-table-column show-overflow-tooltip
					prop="visitSubdistrict"
					label="街(镇)">
				</el-table-column>
				<el-table-column
					prop="visitType"
					label="探访类型"
					:filters="[]"
					:filter-method="filterHandler"
					filter-placement="bottom-end">
				</el-table-column>
				</el-table>
		    </div>
		  </div>
		</div>
		
		<div id="allmap"></div>	
		<!-- 详情弹窗 -->
    	<remarker ref="remarkerDetails" :show.sync="detailsShow"></remarker>
	</div>
	
</template>

<script>
import * as echarts from "echarts";
	import polylineChart from "../components/polyline-chart.vue";
	import remarker from "../components/mark-visit.vue";
	import {taskTotal,tendencyTotal,ageTotal,typeTotal,areaTotal,recordTotal, personList,savePersonLnglat} from "../api/visitApi"

	export default {
		components: {
			polylineChart,
    		remarker,
		},
		data() {
			return {
				map: "",
				newDay: "2022.10.23",
				newTime: "18:25:53",
				color: [],
				colorCross: [],
				visitListData: [{visitTime: '2023-12-12 12:12:12',personIdText: '张三',detailAddress:'新市街道',visitType: '现场'}],
				visitTypeReportData: [{name: '1', value: 12}, {name: '2', value: 23},{name: '3', value: 34},{name: '4', value: 45}],
				// 任务统计
				taskInfo: {},
				// 趋势统计
				tendencyInfo: {
					
				},
				// 年龄统计
				ageInfo: {},
				// 类型统计
				typeInfo: {},
				typeColors: ["#1363EB", "#FFC837", "#5A64F4", "#20CCF8", "#F0882B", "#15CBFC"],
				// 区域老人统计
				areaInfo:{},
				// 探访记录
				recordsInfo: {},
				recordList: [],
				// table高度
				tableHeight: 0,
				// 老人列表
				personList: [],
				detailsShow: false,
			}
		},
		created() {
			this.m_mapLoader().then((AMap) => {
			  this.initMap();
			});
			setInterval(() => {
			  this.getDateTime();
			}, 1000);
			setTimeout(() => {
			this.$topGetToken(token=>{
				console.log('========')
				console.log(token)
			})
			}, 3000)
			setTimeout(() => {
				this.$topGetBigScreenPermissions(pers=>{
			})
			}, 5000)
			this.createColors()
			this.getTaskTotal()
			this.getTendencyTotal()
			this.getAgeTotal()
			this.getTypeTotal()
			this.getAreaTotal()
			this.getRecordTotal()
			window.addEventListener("resize", this.siteHeight);
      this.siteHeight()
		},
		methods: {
			siteHeight() {
				setTimeout(() => {
					var hei = document.querySelector(".item-record").clientHeight;
					this.tableHeight = hei - 50
				})
			},
			initMap() {
			  let map = this.m_initMap();
			  map.on("click", (e) => {
			    console.log("click", e);
			  });
			  this.map = map;
				
				this.getPersonList()
			},
			createColors() {
				this.color.push(new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#188df0' },
          { offset: 1, color: '#83bff6' }
        ]))
				this.color.push(new echarts.graphic.LinearGradient(0, 0, 0, 1, [
				  { offset: 0, color: '#E6A23C' },
				  { offset: 1, color: '#83bff6' }
				]))
				
				this.colorCross.push(new echarts.graphic.LinearGradient(0, 0, 1, 0, [
				    { offset: 1, color: '#188df0' },
				    { offset: 0, color: '#83bff6' }
				  ]))
				this.colorCross.push(new echarts.graphic.LinearGradient(0, 0, 1, 0, [
					  { offset: 1, color: '#E6A23C' },
					  { offset: 0, color: '#83bff6' }
					]))
			},
			getDateTime() {
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth() + 1;
				let dd = new Date().getDate();
				let hh = new Date().getHours();
				let ms =
					new Date().getSeconds() < 10
						? "0" + new Date().getSeconds()
						: new Date().getSeconds();
				let mf =
					new Date().getMinutes() < 10
						? "0" + new Date().getMinutes()
						: new Date().getMinutes();
				this.newDay = yy + "." + mm + "." + dd;
				this.newTime = hh + ":" + mf + ":" + ms;
			},
			
			filterHandler(value, row, column) {
				
			},
			// 任务统计
			getTaskTotal() {
				taskTotal().then(res => {
					if(res.code == 0) {
						this.taskInfo = res.data
					}
				})
			},
			// 探访趋势
			getTendencyTotal() {
				tendencyTotal().then(res => {
					if(res.code == 0) {
						let max = 0
						res.data.dataList.forEach(item => {
							if(item > max) {
								max = item
							}
						})
						this.tendencyInfo = {
							xDate: res.data.time,
							series: [{data: res.data.dataList}],
							maxY: Number(max)
						}
						// this.tendencyXdata = res.data.time
						// this.tendencySeries[0] = {
						// 	data: res.data.dataList
						// }
					}
				})
			},
			// 年龄段统计
			getAgeTotal() {
				ageTotal().then(res => {
					if(res.code == 0) {
						let max = 0
						res.data.total.forEach(item => {
							if(item > max) {
								max = item
							}
						})
						this.ageInfo = {
							xData: res.data.age,
							series: [{data: res.data.total}],
							maxY: Number(max)
						}
					}
				})
			},
			// 类型统计
			getTypeTotal() {
				typeTotal().then(res => {
					if(res.code == 0) {
						// this.typeInfo = {
						// 	data:
						// }
						let data = res.data.map(item => {
							let obj = {}
							obj.name = item.visitType
							obj.value = item.total
							obj.percentage = item.probability
							return obj
						})
						this.typeInfo = {
							data: data
						}
					}
				})
			},
			// 区域统计
			getAreaTotal() {
				areaTotal().then(res => {
					if(res.code == 0) {
						let data = []
						let Xdata = []
						let max = 0
						res.data.forEach(item => {
							data.push(item.personNum)
							Xdata.push(item.areaName)
							if(item.personNum > max) {
								max = item.personNum
							}
						})
						this.areaInfo = {
							xData: Xdata,
							series: [{data: data}],
							maxY: Number(max)
						}
					}
				})
			},
			// 记录
			getRecordTotal() {
				recordTotal({
					limit:10,
					page: 1,
					visitType: '',
					status: ''
				}).then(res => {
					console.log('record',res)
					if(res.code == 0) {
						this.recordList = res.page.list.map(item => {
							if(item.visitTime) {
								item.visitTime = item.visitTime.substr(5, 11)
							}
							if(item.visitType) {
								let mode = []
								if(item.visitType.includes('0')) {
									mode.push('上门探访')
								}
								if (item.visitType.includes('1')){
									mode.push('电话探访')
								}
								if (item.visitType.includes('2')){
									mode.push('视频探访')
								}
								if (item.visitType.includes('3')){
									mode.push('其他')
								}
								item.visitType = mode.join(';')
							}
							return item
						})
					}
				})
			},
			getPersonList() {
				personList().then(res => {
					if (res.code == 0) {
						let list = res.list
							let points  = []
						list.forEach(item => {
							if (!item.lng || !item.lat) {
								let addr = item.residenceProvince+item.residenceCity+item.residenceArea+item.residenceSubdistrict+item.residenceComunity+item.residenceDetail
								console.log('addr', addr)
								this.m_parseAddress(addr).then(data => {
									item.lng = data.lng
									item.lat = data.lat
									savePersonLnglat({
										id: item.id,
										lng: item.lng,
										lat: item.lat
									}).then(saveRes => {
										if (saveRes.code == 0) {
											
										}
									})
								})
							}else {
								points.push({
									lnglat: [item.lng, item.lat],
									data: item
								})
							}
						})
								this.m_addMarkerCluster(this.map, points, (data) => {
									console.log(data)
									this.detailsShow = true
									// 单个老人预警记录
									// 单个老人设备状态
									this.$refs.remarkerDetails.init(data.id, data.gwMac,data)
								})
						this.personList = list
						
					}else {
						this.personList = []
					}
				})
			}
			
		}
	}
</script>

<style lang="scss">
@import "../assets/css/index.scss";
@import "../assets/css/visit.scss";
@import "../assets/css/element-ui.css";

</style>