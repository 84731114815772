import Ajax from './ajax'

const taskTotal = () => {
    return Ajax({
        url: '/visit/big-screen/total'
    })
}
const tendencyTotal = () => {
    return Ajax({
        url: '/visit/big-screen/total-by-day'
    })
}
const ageTotal = () => {
    return Ajax({
        url: '/visit/big-screen/total-by-age'
    })
}
const typeTotal = () => {
    return Ajax({
        url: '/visit/big-screen/total-by-type'
    })
}
const areaTotal = () => {
    return Ajax({
        url: '/visit/big-screen/total-by-area'
    })
}
const recordTotal = (data) => {
    return Ajax({
        url: '/visit/big-screen/record-list',
        data: data,
		method: 'POST'
    })
}
const personList = (data) => {
	return Ajax({
	    url: '/visit/big-screen/person-list',
			method: 'POST'
	})
}

const savePersonLnglat = (data) => {
    return Ajax({
        url: '/visit/big-screen/save-lnglat',
        data: data,
				method: 'POST'
    })
}
const oldInfo = (params) => {
    return Ajax({
        url: '/visit/big-screen/person-info',
        params: params
    })
}

export {
    taskTotal,
    tendencyTotal,
    ageTotal,
    typeTotal,
    areaTotal,
    recordTotal,
	personList,
	savePersonLnglat,
    oldInfo,
}